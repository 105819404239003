<template>
  <div class="page">

    <div v-if="loading" class="loading" style="display: flex; justify-content: center;padding: 60px 0;">
      <Loading />
    </div>

    <SgsExpired v-if="sgsExpired && !loading" :freelanceAktif="freelanceAktif" :username="username"
      :password="password" />
    <Aktivasi
      v-if="!sgsAktif && !loading && !sgsExpired && ($route.path == '/marketing/' || $route.path == '/marketing')" />
    <TermCondition v-if="$route.path == '/marketing/aktivasi'" />

    <div class="navbar" v-if="sgsAktif && !loading && ($route.path == '/marketing/' || $route.path == '/marketing')">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Siswa Get Siswa</div>
    </div>


    <div class="body" v-if="sgsAktif && !loading">
      <div class="summary">
        <div class="card" @click="to('pendaftar/semua')">
          <div class="card-body">
            <h1>{{ summary.SEMUA }}</h1>
            <p>Semua</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/baru')">
          <div class="card-body">
            <h1>{{ summary.BARU }}</h1>
            <p>Daftar Baru</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/upload-berkas')">
          <div class="card-body">
            <h1>{{ summary.UPLOAD_BERKAS }}</h1>
            <p>Upload Berkas</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/validasi')">
          <div class="card-body">
            <h1>{{ summary.VALIDASI }}</h1>
            <p>Divalidasi</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/ujian')">
          <div class="card-body">
            <h1>{{ summary.SUDAH_UJIAN }}</h1>
            <p>Sudah ujian</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/diterima')">
          <div class="card-body">
            <h1>{{ summary.DITERIMA }}</h1>
            <p>Diterima</p>
          </div>
        </div>
        <div class="card" @click="to('pendaftar/ditolak')">
          <div class="card-body">
            <h1>{{ summary.TIDAK_DITERIMA }}</h1>
            <p>Tidak diterima</p>
          </div>
        </div>
      </div>

      <br />

      <h3>Link Referal Pendaftaran Siswa</h3>
      <div class="card">
        <div class="card-body">
          <span>
            {{ ref }}
          </span>
          <button type="button" class="btn btn-primary" @click="copyLink()">Copy Link</button>
        </div>
      </div>

      <br>

      <h3>Pendaftar Terbaru</h3>
      <ul class="pendaftar">
        <li v-for="item, index in siswa" :key="index" @click="to('/siswa/' + item.id)">
          <div>
            <p>
              <b>{{ item.nama_lengkap.toUpperCase() }}</b>
            </p>
            <div class="grid">
              <small>{{ item.kampus }}/{{ item.kode_jurusan }}/{{ item.angkatan_2 }}</small>
              <span>{{ item.no_hp_siswa }}</span>
            </div>
          </div>
          <div>
            <small>
              {{ moment(item.created_at).format('DD/MM/YY HH:mm') }}
            </small>
          </div>
        </li>
      </ul>
      <div class="card" v-if="siswa.length <= 0">
        <div class="card-">
          <p class="mt">
            Tidak ada data
          </p>
        </div>
      </div>

      <br />

      <h3>Kontak Support</h3>
      <ul style="padding-left: 20px;font-size: 14px;">
        <li>Untuk melakukan validasi berkas dan mendapatkan token ujian, atau jika mengalami kesulitan dapat menghubungi
          kontak support di bawah ini</li>
      </ul>
      <ul class="kontak">
        <li v-for="item, index in kontak" :key="index" @click="kontakWA(item)">
          <span>
            <b>{{ item.nama }}</b>
          </span>
          <span>{{ item.no_wa }}</span>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import SgsExpired from '@/components/SgsExpired.vue'
import Aktivasi from '@/components/Aktivasi.vue'
import TermCondition from '@/components/TermCondition.vue'
import { get } from './../api/Api'

export default {
  components: {
    Back, Aktivasi, Loading, TermCondition, SgsExpired
  },
  data() {
    const bulan = {
      januari: '01',
      februari: '01',
      maret: '01',
      april: '01',
      mei: '01',
      juni: '01',
      juli: '01',
      agustus: '01',
      september: '01',
      oktober: '01',
      november: '01',
      desember: '01',
    }

    let angkatan = this.$user.angkatan.split(' ');
    angkatan = bulan[angkatan[0].toLowerCase()] + angkatan[1].slice(-2)

    const alias = this.$user.nama.toLowerCase().replace(/\ /g, '')

    return {
      loading: false,

      ref: 'https://pspp-integrated.com/daftar?ref=' + this.$user.id + '@' + alias,

      sgsAktif: false,
      sgsExpired: false,
      freelanceAktif: false,
      username: '',
      password: '',

      summary: {
        BARU: 0,
        DITERIMA: 0,
        SEMUA: 0,
        UPLOAD_BERKAS: 0,
        SUDAH_UJIAN: 0,
        TIDAK_DITERIMA: 0,
        VALIDASI: 0,
      },
      siswa: [],

      kontak: [
        // {
        //   id: 1,
        //   nama: 'Kak Asna',
        //   no_wa: '62895365076100'
        // },
      ]
    }
  },
  methods: {
    moment,
    copyLink() {
      navigator.clipboard.writeText(this.ref).then(function () {
        alert('Berhasil dicopy')
      }).catch(function (error) {
        console.error('Error copying text: ', error);
      });
    },
    to(url) {
      setTimeout(() => {
        this.$router.push(url)
      }, (150));
    },
    async getStatus() {
      this.loading = true;

      // Cek expired
      const exp = await get('/v1/marketing/siswa/setting?angkatan=' + this.$user.angkatan + '&id_siswa=' + this.$user.id);
      if (exp.data.is_expired != false) {
        this.sgsExpired = true;
        this.freelanceAktif = exp.data.freelance_aktif;
        this.username = exp.data.username;
        this.password = exp.data.password;

      } else {
        const res = await get('/v1/marketing/siswa/getStatusAktivasi?id_siswa=' + this.$user.id)
        if (res.valid) {
          this.sgsAktif = true;
          this.load();
        }
      }
      this.loading = false;
    },
    async load() {
      const res = await get('/v1/marketing/siswa/get?id_siswa_ref=' + this.$user.id)
      this.summary = res.data.summary;
      this.siswa = res.data.data;

      this.kontak = [res.data.kontak[this.$user.id_kampus]]

      this.loading = false;
    },
    kontakWA(item) {
      window.open("https://wa.me/" + item.no_wa);
    }
  },
  mounted() {
    this.getStatus();
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background: #fff;
  color: #333;
  padding: 15px;
  font-weight: 600;
  display: grid;
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid #ccc;
}

.body {
  margin: 20px;
}

.summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  h1,
  p {
    margin: 0;
  }
}

.card {
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
  transition: background 0.3s ease;

  &:active {
    background: #eee;
  }

  .card-body {
    padding: 15px;

    button {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.pendaftar {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:active {
      background: #eee;
    }

    p {
      margin: 0 0 10px;
    }

    .grid {
      span {
        display: flex;
        border: 1px solid var(--orange);
        font-size: 12px;
        padding: 4px;
        width: fit-content;
        color: var(--orange);
        border-radius: 4px;
        margin-top: 5px;
      }
    }

  }
}

.mt {
  padding: 20px 0;
  text-align: center;
}

.kontak {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;

    span {
      &:last-child {
        font-size: 14px;
        color: green;
      }
    }
  }
}
</style>