<template>
  <div class="page">
    <div class="form" v-if="!freelanceAktif">
      <div class="center">
        <p>
          <img src="./../assets/icons/siswa.png" style="height:60px;" />
        </p>
        <h3>Saatnya Beralih dari SGS ke MARKETING FREELANCE</h3>
      </div>

      <p>
        Masa berlaku program Siswa Get Siswa (SGS) sudah berakhir. <br>
        Anda bisa melanjutkan menjadi Marketing Freelance dengan melakukan aktivasi pada formulir di bawah ini.
      </p>

      <br />
      <h3 style="text-align: center;"> KETENTUAN MENDAFTAR MARKETING FREELANCE</h3>
      <ol>
        <li>Direktur Operasional PT. Triando Mandiri Investama (PSPP Penerbangan), selanjutnya disebut sebagai Pihak
          Pertama.</li>
        <li>Marketing freelance sebagai Pihak Kedua, adalah siswa yang telah menyelesaikan KBM dan program SGS (Siswa
          Get Siswa).</li>
        <li>Pihak kedua bersedia mengikuti tata tertib dan aturan yang berlaku sebagai marketing freelance di PT.
          Triando Mandiri Investama (PSPP Penerbangan).</li>
        <li>
          Pihak Kedua akan mendapatkan komisi dari pihak pertama sesuai ketentuan yang telah ditetapkan pihak pertama.
        </li>
        <li>
          Komisi akan direalisasikan selambatnya 14 hari masa kerja sebagai berikut:
          <ul style="list-style: none;padding-left: 20px;">
            <li>a. Komisi Pendaftaran = Rp 100.000</li>
            <li>b. Komisi 10% (dari biaya pendidikan yang sudah disetorkan)</li>
          </ul>
        </li>
        <li>
          Komisi akan direalisasikan apabila calon siswa yang didaftarkan bukan milik marketing lain.
        </li>
        <li>
          Memberikan informasi/penjelasan kepada calon siswa bahwa biaya pendidikan yang sudah disetorkan atau
          dibayarkan ke rekening resmi PSPP Penerbangan tidak dapat dikembalikan kepada calon siswa tersebut
          dengan alasan apapun.
        </li>
        <li>
          Pihak kedua tidak menjanjikan jaminan kerja kepada calon siswa, kecuali calon siswa mendaftar program kelas
          prioritas.
        </li>
        <li>
          Pihak kedua tidak mengalihkan nama calon siswa yang sudah terdaftar dengan nama calon siswa yang
          lain apabila mengundurkan diri.
        </li>
        <li>
          Hal-hal yang belum diatur dalam perjanjian ini akan ditetapkan dalam ketentuan tambahan yang diatur oleh pihak
          pertama.
        </li>
        <li>
          Masa aktif marketing freelance PT. Triando Mandiri Investama (PSPP Penerbangan) sampai dengan 28 Februari 2025
          dan dapat diperpanjang dengan PKWT Marketing.
        </li>
      </ol>

      <label>
        <input type="checkbox" v-model="setuju">
        Saya telah membaca & menyetujui ketentuan menjadi marketing freelance PSPP
      </label>

      <button type="button" class="btn btn-primary" :disabled="!setuju || loading" @click="aktivasi()">
        Aktivasi Marketing Freelance
      </button>
    </div>

    <div class="form" v-if="freelanceAktif">
      <div class="center">
        <p>
          <img src="./../assets/icons/siswa.png" style="height:60px;" />
        </p>
        <h3>Akun Marketing Freelance Aktif</h3>

        <p style="text-align: left;">
          Anda telah aktif menjadi marketing freelance PSPP Penerbangan.
          Berikut data login Anda untuk disimpan:
        </p>

        <table>
          <tr>
            <td>Username</td>
            <td>
              <b>{{ username }}</b>
            </td>
          </tr>
          <tr>
            <td>Password</td>
            <td>
              <b>{{ password }}</b>
            </td>
          </tr>
        </table>

        <p style="text-align: left;">
          Untuk login ke aplikasi Marketing, silakan kunjungi link berikut ini:
        </p>
        <a href="https://marketing.pspp-integrated.com" target="_blank" style="color: #016DFE;">
          https://marketing.pspp-integrated.com
        </a>
      </div>
    </div>

  </div>
</template>

<script>

import { post } from '@/api/Api';
export default {
  data() {
    return {
      loading: false,
      setuju: false,

      data: {}
    }
  },
  props: {
    freelanceAktif: Boolean,
    username: String,
    password: String,
  },
  methods: {
    async aktivasi() {
      this.loading = true;

      const param = {
        id_siswa: this.$user.id,
        nama: this.$user.nama
      }
      const r = await post('/v1/marketing/siswa/aktivasiMarketingFreelance', param)
      if (r.valid) {
        alert('Aktivasi Berhasil')
        this.freelanceAktif = true;

        this.username = r.data.username;
        this.password = r.data.password;
      }

      this.loading = false;
    }
  },
  mounted() {
    console.log(this.freelanceAktif)
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;

  .center {
    text-align: center;
  }

  .form {
    margin: 30px;
  }

  label {
    display: block;
    margin-bottom: 15px;
  }

  ol {
    padding-left: 20px;

    li {
      line-height: 22px;
      margin-bottom: 5px;
    }
  }
}
</style>